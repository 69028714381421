import React from "react";
import "./index.css";

const LinkText = ({ text, onClick }) => {
  return (
    <span className="clickable" onClick={onClick}>
      {text}
    </span>
  );
};

export default LinkText;
