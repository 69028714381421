import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
const PaymentFailed = ({ onClick }) => {
  return (
    <Box
      display={"flex"}
      my={1}
      flexDirection="column"
      justifyContent={"center"}
      alignItems="center"
    >
      <CancelIcon sx={{ fontSize: "6rem" }} color="error" />
      <Typography sx={{ mt: 2, fontSize: { xs: "1rem", md: "1.5rem" } }}>
        Transaction was not successfull
      </Typography>
      <Typography fontSize="0.8rem" sx={{ mt: 0.5, mb: 2 }}>
        Please try again
      </Typography>
      <Button
        variant="outlined"
        sx={{ mt: 1 }}
        color={"secondary"}
        onClick={onClick}
      >
        Go back
      </Button>
    </Box>
  );
};

export default PaymentFailed;
