import { Box, Paper, Typography } from "@mui/material";
import React from "react";

const SubmenuButton = ({ image, title }) => {
  return (
    <Box>
      <Paper elevation={2} sx={{ marginBottom: "0.5rem" }}>
        <Box
          sx={{
            height: {
              xs: "5rem",
              lg: "6rem",
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              backgroundColor: "#f5f5f5",
              cursor: "pointer",
            },
            backgroundImage: `url(${image})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></Box>
      </Paper>
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: {
            xs: "0.7rem",
            lg: "0.8rem",
          },
          textAlign: "center",
          marginTop: "0.2rem",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default SubmenuButton;
