import { createSlice } from "@reduxjs/toolkit";
import { PRODUCTION } from "../const";

//stores the state of all loaders across the application
//showing the loader or not depends on whether the loader is in the state or not
export const loadingStatus = createSlice({
  name: "loadingStatus",
  initialState: {},
  reducers: {
    setLoadingStatusFor: (state, action) => {
      //loop through the action so that multiple states can be set as loading
      Object.keys(action.payload).forEach((key) => {
        state[key] = 1; // setting a garbage value, just to store the key name
      });
    },
    clearLoadingStatus: (state, action) => {
      //loop through the action so that multiple states can be set as loading
      Object.keys(action.payload).forEach((key) => {
        state[key] = undefined; // setting undefined to remove the key from the state
      });
    },
  },
});

export const { setLoadingStatusFor, clearLoadingStatus } =
  loadingStatus.actions;

export default loadingStatus.reducer;
