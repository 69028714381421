import { DraftsOutlined, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  FormControl,
  InputAdornment,
  NativeSelect,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  getAllDoctorsByInstitute,
  getAllDoctorsByInstituteV2,
} from "../../app/apiManager/doctorServices";
import { getAllDoctors } from "../../app/apiManager/doctorServices";
import searchIcon from "../../assets/searchIcon.gif";
import DoctorCard from "../../components/DoctorCard/DoctorCard";
import { post } from "../../app/apiManager/apiManager";
import palette from "../../theme/palette";
import { useCallback } from "react";

const StyledTabButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButton-root": {
    margin: 0,
    borderRadius: "0px !important",
    fontSize: "0.6rem",
    padding: "0.1rem 0.15rem",
  },
  "& .MuiToggleButton-root.Mui-selected": {
    backgroundColor: palette.light.primary.main,
    color: "#fff",
    borderRadius: "5px !important",
  },
  "& .MuiToggleButton-root.Mui-selected:hover": {
    backgroundColor: palette.light.primary.main,
    color: "#fff",
  },
}));

const SearchDoctor = ({
  setSpecialities,
  doctorFilterObj,
  setDoctorFilterObj,
}) => {
  const [allDoctors, setAllDoctors] = useState([]);
  const [showing, setShowing] = useState("ALL_DOCTORS");

  const [selectedSpeciality, setSelectedSpeciality] = useState("All");
  const searchRef = useRef(null);

  const fetchDoctors = useCallback(async () => {
    try {
      const doctors = await getAllDoctorsByInstituteV2({
        fromDate: new Date().toISOString().split("T")[0],
        branchId: process.env.REACT_APP_BRANCH_ID,
      });
      setAllDoctors(doctors);
      setSpecialities(doctors.map((doctor) => doctor.specialization));
      filterDoctors("");
    } catch (error) {
      console.log(error);
    }
  }, [setAllDoctors]);

  useEffect(() => {
    fetchDoctors();
  }, [fetchDoctors]);

  const filterDoctors = (filteringOptions) => {
    let doctors = [...allDoctors];
    const { doctorName, specialization, date, strictlyTodayCheck } =
      filteringOptions;

    if (doctorName !== "") {
      doctors = doctors.filter((doctor) => {
        let name = doctor.title + doctor.firstName + doctor.lastName;
        name = name.toLowerCase();
        let nameKeyword = doctorName.replace(" ", "").toLowerCase();
        nameKeyword = nameKeyword.replace(".", "");
        return name.includes(nameKeyword);
      });
    }

    if (
      specialization !== "" &&
      specialization !== "All Specialities" &&
      specialization !== undefined
    ) {
      doctors = doctors.filter((doctor) => {
        return doctor.specialization === specialization;
      });
    }

    if (date !== "") {
      doctors = doctors.filter((doctor) => {
        let hasSessions = false;
        for (let index = 0; index < doctor.sessions.length; index++) {
          const session = doctor.sessions[index];
          if (new Date(session.timeStart) >= date) {
            hasSessions = true;
            break;
          }
        }
        return hasSessions;
      });
    }

    if (strictlyTodayCheck) {
      doctors = doctors.filter((doctor) => {
        let hasSessions = false;
        const filteringDate = strictlyTodayCheck.toISOString().split("T")[0];
        for (let index = 0; index < doctor.sessions.length; index++) {
          const session = doctor.sessions[index];
          const sessionStartDate = new Date(session.timeStart)
            .toISOString()
            .split("T")[0];
          if (sessionStartDate === filteringDate) {
            hasSessions = true;
            break;
          }
        }
        return hasSessions;
      });
    }

    return doctors;
  };

  const handleShowingChange = (event, newValue) => {
    if (newValue === "NOW_AVAILABLE") {
      setDoctorFilterObj((prev) => ({
        ...prev,
        strictlyTodayCheck: new Date(),
      }));
    } else {
      setDoctorFilterObj({
        doctorName: "",
        specialization: "",
        date: "",
      });
    }
    setShowing(newValue);
  };

  const filteredDoctors = filterDoctors(doctorFilterObj);

  useEffect(() => {
    if (doctorFilterObj.strictlyTodayCheck) {
      setShowing("NOW_AVAILABLE");
    } else {
      setShowing("ALL_DOCTORS");
    }
  }, [doctorFilterObj]);

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      pt={2}
      sx={{
        pl: { xs: 0, md: 2 },
      }}
      alignItems={"center"}
      flexDirection="column"
      ref={searchRef}
    >
      <Box
        sx={{
          height: "25rem",
          width: "100%",
          borderRadius: 3,
          pl: 2,
          pb: 3,
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={1}
        >
          <Typography
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#e4e4e4",
              fontSize: "1rem",
              fontWeight: 600,
            }}
          >
            Found {filteredDoctors.length} Doctor
            {filteredDoctors.length === 1 ? "" : "s"}
          </Typography>

          <StyledTabButtonGroup
            color="primary"
            value={showing}
            exclusive
            onChange={handleShowingChange}
            size="small"
            sx={{
              borderRadius: "5px",
              "& .MuiToggleButton-root": {
                margin: 0,
              },
            }}
          >
            <ToggleButton value="NOW_AVAILABLE">Now available</ToggleButton>
            <ToggleButton value="ALL_DOCTORS">All doctors</ToggleButton>
          </StyledTabButtonGroup>
        </Box>

        <Box
          sx={{
            height: "22.5rem",
            overflow: "auto",
            scrollbarWidth: "thin",
          }}
        >
          {filteredDoctors.map((doctor, index) => {
            return (
              <DoctorCard
                doctor={doctor}
                key={index}
                selectFirstAvailableSession={
                  doctorFilterObj.strictlyTodayCheck ? true : false
                }
              />
            );
          })}
          {filteredDoctors.length === 0 && <img src={searchIcon} alt="" />}
        </Box>
      </Box>
    </Box>
  );
};

export default SearchDoctor;
