import { post, postAsync } from "./apiManager";

export const placeAppointment = (body, onSuccess, onFailed, onComplete) => {
  post(
    "Appointment",
    body,
    "placeAppointment",
    onSuccess,
    onFailed,
    onComplete
  );
};

export const getAppointments = (
  PatientMobile,
  onSuccess = () => {},
  onFailed = () => {},
  onComplete = () => {}
) => {
  fetch(
    `${process.env.REACT_APP_MEDICA_URL}AppointmentPrescriptionRecord/GetAppointment`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        UserId: 2,
      },
      body: JSON.stringify({
        PatientMobile,
      }),
    }
  )
    .then((res) => res.json())
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onFailed(error);
    })
    .finally(() => {
      onComplete();
    });
};
