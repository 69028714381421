import { Box, Button, Chip, LinearProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPatientsList } from "../../app/apiManager/patientServices";
import { saveSteps } from "../../reducers/appointmentInfoSlice";
import Scrollbar from "../Scrollbar";
import TableHeading from "./TableHeading";
import TableRow from "./TableRow";

function PatientsTable() {
  const phoneNo = useSelector((state) => state.appointmentInfo.phoneNo);

  const [selectedId, setSelectedId] = useState("");
  const dispatch = useDispatch();
  const [patientList, setPatientList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [attempts, setAttepmts] = useState(0);

  useEffect(() => {
    if (attempts < 3) {
      let phoneNumber = phoneNo;
      // remove +94, 94, from phone number
      if (phoneNumber.length === 9) {
        phoneNumber = "0" + phoneNumber;
      } else if (phoneNumber.startsWith("+94")) {
        phoneNumber = `0${phoneNumber.replace("+94", "")}`;
      } else if (phoneNumber.startsWith("94")) {
        phoneNumber = `0${phoneNumber.replace("94", "")}`;
      }

      getPatientsList(
        phoneNumber,
        (data) => {
          if (data) {
            setPatientList(data);
            setAttepmts(3);
          } else {
            setAttepmts(attempts + 1);
          }
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, [attempts]);

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          height: {
            xs: "14rem",
            md: "13rem",
          },
          mt: 1,
          position: "relative",
        }}
      >
        <TableHeading />

        {loading ? (
          <LinearProgress />
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "8rem",
              overflowY: "auto",
              scrollbarWidth: "thin",
            }}
          >
            <>
              {patientList.map((list, index) => {
                return (
                  <TableRow
                    name={list.FirstName + " " + list.LastName}
                    dob={list.DateOfBirth}
                    patientId={list.Id}
                    email={list.Email}
                    gender={list.Gender}
                    key={index}
                    patient={list}
                    selectedId={selectedId}
                    onClick={setSelectedId}
                  />
                );
              })}
            </>
            {patientList.length === 0 && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                py={2}
              >
                No patients available
                <Button
                  variant="text"
                  sx={{ p: 0, pl: 1 }}
                  onClick={() => {
                    dispatch(
                      saveSteps({
                        step: 5,
                        name: "",
                        specialization: "",
                        date: "",
                      })
                    );
                  }}
                >
                  add patient
                </Button>
              </Box>
            )}

            <Box pt={2}></Box>
          </Box>
        )}
        {patientList.length > 0 && (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            py={2}
            sx={{
              position: "sticky",
              bottom: 0,
            }}
          >
            <Typography>Add new family member</Typography>
            <Button
              variant="text"
              sx={{
                py: 0,
                ml: 1,
                px: 1,
                minWidth: "auto",
              }}
              onClick={() => {
                dispatch(
                  saveSteps({
                    step: 5,
                    name: "",
                    specialization: "",
                    date: "",
                  })
                );
              }}
            >
              ADD
            </Button>
          </Box>
        )}
      </Box>
    </div>
  );
}

export default PatientsTable;
