// const TTL_HOURS = 24; //time to live(expiry date)
const SECONDS_PER_HOUR = 3600;
const MILISECONDS_PER_SECONDS = 1000;
// const EXPIRESIN = SECONDS_PER_HOUR * MILISECONDS_PER_SECONDS * TTL_HOURS; //expire time in miliseconds

const getExpiry = (TTL_HOURS = 24) => {
  const today = new Date();
  const expiry = new Date(
    today.getTime() + SECONDS_PER_HOUR * MILISECONDS_PER_SECONDS * TTL_HOURS
  );
  return expiry;
};

const isExpired = (date) => {
  const expDate = new Date(date);
  const today = new Date();

  if (today > expDate) {
    return true;
  } else {
    return false;
  }
};

export { getExpiry, isExpired };
