import { useRef, useState } from "react";

export default function useOTPAutoType() {
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const verifyBtnRef = useRef();
  const otpRefs = [ref1, ref2, ref3, ref4, ref5, ref6];

  const [otp, setOtp] = useState("");

  const onTypeOtp = (e, index) => {
    const { value } = e.target;

    //if first value length equals 6 chars set all values
    if (value.length === 6 && index === 0) {
      setOtp(value);
      let values = value.split("");
      otpRefs.forEach((ref, index) => {
        ref.current.value = values[index];
      });
      verifyBtnRef.current.removeAttribute("disabled");
      return;
    }
    // console.log("value.length", value.length);
    if (value.length === 1) {
      let otpArray = otp.split("");
      otpArray[index] = value;
      setOtp(otpArray.join(""));
      if (index + 1 < 6) {
        otpRefs[index + 1].current.focus();
      } else {
        otpRefs[5].current.blur();
        verifyBtnRef.current.removeAttribute("disabled");
        // verifyBtnRef.current.focus();
      }
    } else if (value.length > 1) {
      //set the values again
      let otpArray = otp.split("");
      otpRefs[index].current.value = otpArray[index];
      if (index + 1 < 6) {
        otpRefs[index + 1].current.focus();
      } else {
        verifyBtnRef.current.removeAttribute("disabled");
        // verifyBtnRef.current.focus();
      }
    }
  };
  return {
    otpRefs,
    verifyBtnRef,
    onTypeOtp,
    otp,
  };
}
