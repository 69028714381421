import { LocationCity, MyLocation } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import React from "react";
import FlexBox from "../FlexBox/FlexBox";
import BadgeIcon from "@mui/icons-material/Badge";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import InsertChartOutlinedTwoToneIcon from "@mui/icons-material/InsertChartOutlinedTwoTone";

const footerButtonStyle = {
  fontSize: {
    xs: "2.5rem",
    lg: "2.8rem",
  },
  color: "white",
  marginTop: "0.5rem",
};

const Footer = () => {
  const footerButton = (
    <Box display={"flex"} justifyContent={"center"}>
      <BadgeIcon sx={footerButtonStyle} />
    </Box>
  );
  return (
    <Box
      sx={{
        height: "5rem",
        position: "fixed",
        width: "100%",
        bottom: 0,
        left: 0,
      }}
    >
      <Grid
        container
        sx={{
          backgroundColor: "primary.dark",
          borderRadius: {
            xs: 0,
            md: "1rem 1rem 0 0",
          },
          height: "100%",
          paddingTop: "1rem",
        }}
      >
        <Grid item xs>
          <Box display={"flex"} justifyContent={"center"}>
            <LocationOnIcon sx={footerButtonStyle} />
          </Box>
        </Grid>
        <Grid item xs>
          <Box display={"flex"} justifyContent={"center"}>
            <PermContactCalendarOutlinedIcon sx={footerButtonStyle} />
          </Box>
        </Grid>
        <Grid item xs>
          <Box display={"flex"} justifyContent={"center"}>
            <MenuBookOutlinedIcon sx={footerButtonStyle} />
          </Box>
        </Grid>
        <Grid item xs>
          <Box display={"flex"} justifyContent={"center"}>
            <InsertChartOutlinedTwoToneIcon sx={footerButtonStyle} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
