import { Box, Button, Grid, Paper } from "@mui/material";
import React from "react";
import Footer from "../../components/Footer/Footer";
import MenuButton from "../../components/MenuButton/MenuButton";
import PatientProfileCard from "../../components/PatientProfileCard/PatientProfileCard";
import SubmenuButton from "../../components/SubmenuButton/SubmenuButton";
import MedicaPrescription from "./MedicaPrescription";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import SoapIcon from "@mui/icons-material/Soap";
import imageDieting from "../../assets/dieting.png";
import imagePersonalHealth from "../../assets/personalHealth.png";
import imageLifeStyle from "../../assets/life-style-medicine.png";
import imageLongCorona from "../../assets/long-corona.png";
import imageMentalHealth from "../../assets/mental-health.png";
import imagePhysicalHealth from "../../assets/physical-health.png";
import FlexBox from "../../components/FlexBox/FlexBox";
import FeatureCard from "./FeatureCard";

const menuCardStyles = {
  fontSize: { xs: "2rem", lg: "4rem" },
};

const SubmenuButtonStyles = {
  fontSize: { xs: "2rem", lg: "4rem" },
};

const Overview = () => {
  const menuCards = [
    <MenuButton
      icon={<ReceiptLongIcon sx={menuCardStyles} />}
      title="Prescription"
    />,
    <MenuButton
      icon={<ContentPasteIcon sx={menuCardStyles} />}
      title="Reports"
    />,
    <MenuButton
      icon={<VolunteerActivismIcon sx={menuCardStyles} />}
      title="History"
    />,
    <MenuButton icon={<SoapIcon sx={menuCardStyles} />} title="Allergies" />,
  ];
  const submenuCards = [
    <SubmenuButton image={imageLongCorona} title="Long Corona" />,
    <SubmenuButton image={imageMentalHealth} title="Mental Health" />,
    <SubmenuButton image={imagePhysicalHealth} title="Physical Health" />,
    <SubmenuButton image={imageDieting} title="Diet Plan" />,
    <SubmenuButton image={imageLifeStyle} title="Life Style Medicine" />,
    <SubmenuButton
      image={imagePersonalHealth}
      title="Personal Health Records"
    />,
  ];
  return (
    <Box
      sx={{
        padding: {
          xs: "0rem",
          md: "1rem",
        },
        height: "100vh",
      }}
    >
      <PatientProfileCard />
      <Grid container sx={{ marginTop: "1rem", height: "68vh" }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
              display: "flex",
              overflowX: "auto",
              scrollbarWidth: "thin",
              gap: "1rem",
              ...(menuCards.length <= 4 && {
                justifyContent: "center",
              }),
            }}
          >
            {menuCards.map((card) => card)}
          </Box>
          <FlexBox>
            <Button
              variant="contained"
              fullWidth
              sx={{
                boxShadow: "none",
                display: { md: "none" },
                marginX: "0.7rem",
                my: "0.5rem",
                py: "0.8rem",
              }}
            >
              Request a Doctor Appointment
            </Button>
          </FlexBox>
          <Grid
            container
            sx={{
              marginTop: "1rem",
              paddingX: {
                xs: "1rem",
                md: "1rem",
              },
            }}
            spacing={1}
          >
            {submenuCards.map((card) => (
              <Grid item xs={4} md={2}>
                {card}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ paddingLeft: { xs: 0, md: "1rem" } }}
          // display="flex"
          // justifyContent="center"
          // alignItems={"center"}
        >
          <Paper
            elevation={2}
            sx={{
              padding: "1rem",
              height: "100%",
              marginBottom: { xs: "6rem", md: "unset" },
            }}
          >
            {/* <h1>Banner Image</h1> */}
            {/* <MedicaPrescription /> */}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              <FeatureCard />
              <FeatureCard />
              <FeatureCard />
              <FeatureCard />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
};

export default Overview;
