import { createSlice } from "@reduxjs/toolkit";
import { PRODUCTION } from "../const";

export const appointmentInfo = createSlice({
  name: "appointmentInfo",
  initialState: {
    step: 0,
    sessionExpireOn: new Date("2023-01-01"),
    selectedDoctor: PRODUCTION ? 17114 : 6,
    appointmentNo: "0",
    appointmentId: "-1",
    appointmentDateTime: "",
    phoneNo: "",
    typedCaptcha: "",
    OTPNo: "",
    OTPVerified: false,
    patientId: "",
    patientName: "",
    patientEmail: "",
    sessionId: "",
    sessionType: "",
    userId: "",
    txnId: "",
    reference: "",
    doctorDetails: {
      name: "",
      specialization: "",
      regNo: "",
      imageLink: "",
    },
  },
  reducers: {
    saveSteps: (state, action) => {
      state.step = action.payload.step;
    },
    setSessionExpireOn: (state, action) => {
      state.sessionExpireOn = action.payload.sessionExpireOn;
    },
    setStep: (state, action) => {
      state.step = action.payload.step;
    },
    setSessionInfo: (state, action) => {
      state.appointmentDateTime = action.payload.appointmentDateTime;
      state.sessionId = action.payload.sessionId;
      state.sessionType = action.payload.sessionType;
    },
    setPhoneNo: (state, action) => {
      state.phoneNo = action.payload.phoneNo;
    },
    setTypedCaptcha: (state, action) => {
      state.typedCaptcha = action.payload.typedCaptcha;
    },
    setOTP: (state, action) => {
      state.OTPNo = action.payload.OTPNo;
    },
    setPatientDetails: (state, action) => {
      state.patientName = action.payload.patientName;
      state.patientId = action.payload.patientId;
      state.patientEmail = action.payload.patientEmail;
    },
    setOTPVerified: (state, action) => {
      state.OTPVerified = action.payload.OTPVerified;
    },
    clearSteps: (state) => {
      state.step = "";
      state.name = "";
      state.specialization = "";
      state.date = "";
    },
    setPaymentDetails: (state, action) => {
      state.txnId = action.payload.txnId;
      state.reference = action.payload.reference;
    },
    setSelectedDoctor: (state, action) => {
      state.selectedDoctor = action.payload.selectedDoctor;
    },
    setDoctorDetails: (state, action) => {
      state.doctorDetails.name = action.payload.name;
      state.doctorDetails.specialization = action.payload.specialization;
      state.doctorDetails.regNo = action.payload.regNo;
      state.doctorDetails.imageLink = action.payload.imageLink;
    },
    setAppointmentNo: (state, action) => {
      state.appointmentNo = action.payload.appointmentNo;
    },
    setAppointmentId: (state, action) => {
      state.appointmentId = action.payload.appointmentId;
    },
    clearAll: (state) => {
      state.step = 0;
      state.name = "";
      state.selectedDoctor = PRODUCTION ? 17114 : 6;
      state.setAppointmentId = "";
      state.appointmentDateTime = "";
      state.typedCaptcha = "";
      state.OTPNo = "";
      state.OTPVerified = false;
      state.specialization = "";
      state.date = "";
      state.patientId = "";
      state.patientEmail = "";
      state.sessionId = "";
      state.userId = "";
      state.txnId = "";
      state.reference = "";
    },
  },
});

export const {
  saveSteps,
  setSessionInfo,
  setStep,
  setSessionExpireOn,
  clearSteps,
  setPhoneNo,
  setOTP,
  setOTPVerified,
  setTypedCaptcha,
  setPatientDetails,
  setPaymentDetails,
  setSelectedDoctor,
  setDoctorDetails,
  setAppointmentId,
  clearAll,
  setAppointmentNo,
} = appointmentInfo.actions;
export default appointmentInfo.reducer;
