import { useEffect } from "react";
import { useState } from "react";
import { getCaptcha } from "../app/apiManager/apiManager";
import { CircularProgress } from "@mui/material";

export default function useCaptcha() {
  const [captcha, setCaptcha] = useState({
    Base64: null,
    Guid: "",
  });

  const [resetCaptcha, setResetCaptcha] = useState(true);

  useEffect(() => {
    if (resetCaptcha) {
      setCaptcha({
        Base64: null,
        Guid: "",
      });
      getCaptcha(
        (data) => {
          setCaptcha({
            Base64: data.Base64,
            Guid: data.Guid,
          });
          setResetCaptcha(false);
        },
        () => {}
      );
    }
  }, [resetCaptcha]);

  const CaptchaImageComponent = () => {
    return captcha.Base64 ? (
      <img
        src={`data:image/jpeg;base64,${captcha.Base64}`}
        alt=""
        height={"40px"}
      />
    ) : (
      <CircularProgress size={25} />
    );
  };

  return {
    captcha,
    resetCaptcha: setResetCaptcha,
    CaptchaImageComponent,
  };
}
