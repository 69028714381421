import { Box, Paper, Typography } from "@mui/material";
import React from "react";

const MenuButton = ({ icon, title }) => {
  return (
    <Paper elevation={2}>
      <Box
        sx={{
          width: {
            xs: "4.3rem",
            lg: "8rem",
          },
          height: {
            xs: "4.3rem",
            lg: "8rem",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            backgroundColor: "#f5f5f5",
            cursor: "pointer",
          },
        }}
      >
        <div>{icon}</div>
        <Typography
          sx={{
            fontSize: {
              xs: "0.7rem",
              lg: "1rem",
            },
          }}
        >
          {title}
        </Typography>
      </Box>
    </Paper>
  );
};

export default MenuButton;
