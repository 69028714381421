import { createSlice } from "@reduxjs/toolkit";
import { PRODUCTION } from "../const";

export const appointmentStatus = createSlice({
  name: "appointmentStatus",
  initialState: {
    step: 1,
    appointmentStatusExpire: new Date(),
    appointment: {},
    sessionId: "",
  },
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setAppointment: (state, action) => {
      state.appointment = action.payload.appointment;
    },
    setAppointmentStatusExpire: (state, action) => {
      state.appointmentStatusExpire = action.payload;
    },
    clearAppointmentStatus: (state, action) => {
      state.step = 1;
      state.appointmentStatusExpire = new Date();
      state.appointment = {};
    },
  },
});

export const {
  setStep,
  setAppointment,
  setAppointmentStatusExpire,
  clearAppointmentStatus,
} = appointmentStatus.actions;

export default appointmentStatus.reducer;
