//implements old school string formatting
/* console.log(
   formattedString(process.env.REACT_APP_MESSAGE_TO_DOCTOR, [
     "appointment 10",
     "2022.02.21",
     "https://www.google.com",
   ])
 );*/
export const formattedString = (format, args = []) => {
  return format.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
};
