import { Box, Chip, Grid, Typography } from "@mui/material";
import React from "react";
import docotorImg from "../../assets/doctor.jpg";

const DoctorSummery = ({ doctorImage, name, qualifications }) => {
  const doctorImageLink = `${process.env.REACT_APP_VC_DOMAIN}${process.env.REACT_APP_IMAGE_PATH}${doctorImage}.png`;

  return (
    <div>
      <Grid container mt={2}>
        <Grid item>
          {/* <Box
            sx={{
              borderRadius: "50%",
              border: "1px solid #787A91",
              backgroundImage: `url(${doctorImage})`,
              backgroundPosition: "center",
              backgroundSize: "cover",

              height: "6rem",
              width: "6rem",
            }}
          ></Box> */}
          <img
            src={doctorImageLink}
            alt=""
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = docotorImg;
            }}
            style={{ borderRadius: "50%", width: "60px", height: "60px" }}
          />
        </Grid>
        <Grid item xs pl={2}>
          <Typography fontWeight={"bold"} fontSize={"1.2rem"}>
            {name}
          </Typography>
          <Typography
            fontSize={"0.8rem"}
            sx={{
              color: "#787A91",
            }}
          >
            {qualifications}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default DoctorSummery;
