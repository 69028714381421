import { createSlice } from "@reduxjs/toolkit";

export const placeAppointmentSlice = createSlice({
  name: "placeAppointment",
  initialState: {
    doctor: {},
    session: {},
  },
  reducers: {
    setDoctor: (state, action) => {
      state.doctor = action.payload;
    },
    setSession: (state, action) => {
      state.session = action.payload;
    },
  },
});

export const { setDoctor, setSession } = placeAppointmentSlice.actions;

export default placeAppointmentSlice.reducer;
