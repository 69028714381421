import { createSlice } from "@reduxjs/toolkit";

const gasAuthSlice = createSlice({
  name: "gasAuth",
  initialState: {
    gasAuthHeader: "",
    browserId: "",
  },
  reducers: {
    setGasAuth: (state, action) => {
      state.gasAuthHeader = action.payload.gasAuthHeader;
      state.browserId = action.payload.browserId;
    },
    clearGasAuth: (state, action) => {
      state.gasAuthHeader = "";
      state.browserId = "";
    },
  },
});

export const { setGasAuth, clearGasAuth } = gasAuthSlice.actions;
export default gasAuthSlice.reducer;
