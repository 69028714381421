import { Person } from "@mui/icons-material";
import { Box, Typography, styled } from "@mui/material";
import React from "react";

const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  gap: 2,
  "&:hover": {
    cursor: "pointer",
  },
});
const SignedInAs = ({ patientName, onClick }) => {
  return (
    <StyledBox onClick={onClick}>
      <Person />
      <Typography variant={"body1"}>{patientName}</Typography>
    </StyledBox>
  );
};

export default SignedInAs;
