import { Box, Chip, Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import getOriginalDateTime from "../../utils/formatAMPM";

const Subtitle = ({ title, sessionId, appointmentDateTime }) => {
  const date = new Date(appointmentDateTime);

  return (
    <div>
      <Typography
        fontWeight={"bold"}
        fontSize={"1.3rem"}
        sx={{ mt: { xs: 2, sm: 0 }, mb: { xs: 2, sm: 0 } }}
      >
        Appointment Info
      </Typography>
      <Box mt={1}>
        <Grid container>
          <Grid item xs>
            <Typography fontWeight={"medium"}>Ref No</Typography>
            <Typography>{sessionId} </Typography>
          </Grid>
          <Grid item xs>
            <Typography fontWeight={"bold"}>Appoinment Date</Typography>
            <Typography>
              {date
                ? getOriginalDateTime(appointmentDateTime).toLocaleDateString()
                : ""}
            </Typography>
          </Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid item fontWeight={"bold"} xs>
            <Typography fontWeight={"bold"}>Appoinment Time</Typography>
            <Typography>
              {date
                ? format(getOriginalDateTime(appointmentDateTime), "p")
                : ""}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Typography fontWeight={"bold"} fontSize={"1.3rem"} mt={1}>
        {title}
      </Typography>
    </div>
  );
};

export default Subtitle;
