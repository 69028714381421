import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import Overview from "./pages/Overview/Overview";
import SearchDoctor from "./pages/SearchDoctor/SearchDoctor";
import StatusPage from "./pages/StatusPage/StatusPage";
import Test from "./pages/Test";
import ThemeProvider from "./theme";
import SignIn from "./pages/SignIn/SignIn";
import Registration from "./pages/Registration/Registration";
import SelectPatient from "./pages/SelectPatient/SelectPatient";
import ViewAppointments from "./pages/Home/ViewAppointments";
import AccountDeleteRequest from "./pages/AccountDeleteRequest/AccountDeleteRequest";

function App() {
  return (
    <div className="App">
      <ThemeProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path="/" element={<Home />}>
              <Route path="sign-in" element={<SignIn />} />
              <Route path="account-delete-request" element={<AccountDeleteRequest />} />
              <Route path="/register" element={<Registration />} />
              <Route path="/select-patient" element={<SelectPatient />} />
              <Route path="/view-appointments" element={<ViewAppointments />} />
            </Route>
            <Route path="/app/overview" element={<Overview />} />
            <Route path="/search" element={<SearchDoctor />} />
            <Route path="/test" element={<Test />} />
            <Route path="/status" element={<StatusPage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
