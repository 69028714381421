import { Box, Typography } from "@mui/material";
import React from "react";

const SELECTED_COLOR = "#16003B";
const COLOR = "#637373";
const ICON_BACKGROUND = "#BBBBBB";

const MenuItem = ({
  title,
  icon,
  onClick,
  selected,
  p = 2,
  gap = 1,
  sx = { display: "flex" },
}) => {
  return (
    <div onClick={onClick} style={{ ...sx }}>
      <Box
        p={p}
        gap={gap}
        color={selected ? SELECTED_COLOR : COLOR}
        sx={{
          ...sx,
          "&:hover": {
            color: SELECTED_COLOR,
            cursor: "pointer",
          },
        }}
      >
        <Box>{icon}</Box>
        <Typography fontWeight={"bold"} fontSize="1rem">
          {title}
        </Typography>
      </Box>
    </div>
  );
};

export default MenuItem;
