export const convertToCurrency = ({ amount, currency }) => {
  if (amount) {
    let strNumber = amount.toString();
    const splitted = strNumber.split("");
    for (let i = splitted.length - 3; i > 0; i -= 3) {
      splitted.splice(i, 0, ",");
    }

    return Number.isInteger(amount)
      ? `${currency} ${splitted.join("")}.00 `
      : `${currency} ${Number(amount).toFixed(2)}`;
  }
  return `${currency} 0.00`;
};
