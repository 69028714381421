import { Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";

const RESEND_OTP_TIMEOUT = 60;
export default function useOTPTimer(onClick) {
  const [disabled, setResendOTPDisabled] = useState(false);
  const [timer, setTimer] = useState(RESEND_OTP_TIMEOUT);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    let timeout = null;
    if (timeout !== 0 && disabled) {
      timeout = setTimeout(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }

    if (timer === 0) {
      setResendOTPDisabled(false);
      setTimer(RESEND_OTP_TIMEOUT);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [timer, disabled]);

  const OTPTimerComponent = () => {
    return (
      <Button
        variant="contained"
        disabled={disabled || showLoader}
        onClick={() => {
          onClick();
        }}
      >
        {disabled ? `Resend ${timer}` : "Send OTP"}

        {showLoader && (
          <CircularProgress
            size={15}
            sx={{
              ml: 1,
            }}
          />
        )}
      </Button>
    );
  };

  return {
    OTPTimerComponent,
    setResendOTPDisabled,
    isSendOTPDisabled: disabled,
    showLoader: setShowLoader,
  };
}
