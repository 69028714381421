import { Box } from "@mui/material";
import React from "react";

const ContentCard = ({ children, sx }) => {
  return (
    <Box sx={{ backgroundColor: "white", borderRadius: 1, p: 1.5, ...sx }}>
      {children}
    </Box>
  );
};

export default ContentCard;
