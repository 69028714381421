import { post } from "./apiManager";

export const sendNotification = (body, onSuccess, onFailed, onComplete) => {
  post(
    "Notification",
    body,
    "sendNotification",
    onSuccess,
    onFailed,
    onComplete
  );
};

export const sendNotificationV2 = (data, onSuccess, onFailed, onComplete) => {
  fetch(`${process.env.REACT_APP_NEW_API_BASE_URL}notification`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ data }),
  })
    .then((res) => res.json())
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onFailed(error);
    });
};
