import { createSlice } from "@reduxjs/toolkit";

const signInSlice = createSlice({
  name: "signIn",
  initialState: {
    Id: "",
    FirstName: "",
    LastName: "",
    Email: "",
    DateOfBirth: "",
    Title: "",
    Gender: "",
    UniqueId: "",
    NIC: "",
    Mobile: "",
  },
  reducers: {
    signIn: (state, action) => {
      state.Id = action.payload.Id;
      state.FirstName = action.payload.FirstName;
      state.LastName = action.payload.LastName;
      state.Email = action.payload.Email;
      state.DateOfBirth = action.payload.DateOfBirth;
      state.Title = action.payload.Title;
      state.Gender = action.payload.Gender;
      state.UniqueId = action.payload.UniqueId;
      state.NIC = action.payload.NIC;
      state.Mobile = action.payload.Mobile;
    },
    signOut: (state) => {
      state.Id = "";
      state.FirstName = "";
      state.LastName = "";
      state.Email = "";
      state.DateOfBirth = "";
      state.Title = "";
      state.Gender = "";
      state.UniqueId = "";
      state.NIC = "";
      state.Mobile = "";
    },
  },
});

export const { signIn, signOut } = signInSlice.actions;
export default signInSlice.reducer;
