import { Close, Done } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { APPOINTMENT_STATUS } from "../../const";

const PatientsDot = ({
  myPosition,
  index,
  current = 0,
  status = "pending",
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        height: "3rem",
        margin: "0.2rem",
        width: "3rem",
        borderRadius: "50%",
        backgroundColor: "#C8F9E0",
        // backgroundColor: index < current ? "#25df43" : "#ADADAD",
        ...(myPosition === index && {
          backgroundColor: "#27C064",
        }),
        ...(status === "COMPLETED" && {
          backgroundColor: "#C8F9E0",
        }),
        ...(status === "CANCELLED" && {
          backgroundColor: "#F9C8C8",
        }),
        ...(current === index && {
          border: "3px solid red",
        }),
      }}
      display="flex"
      justifyContent={"center"}
      alignItems="center"
    >
      <Typography
        fontWeight={"bold"}
        sx={{ color: "#2C2C2C", position: "absolute", fontSize: "1.2rem" }}
      >
        {index}
      </Typography>
      {status === "COMPLETED" && (
        <Done sx={{ color: "#27C064", fontSize: "3rem", zIndex: 2 }} />
      )}

      {status === "CANCELLED" && (
        <Close sx={{ color: "#F84646", fontSize: "3rem", zIndex: 2 }} />
      )}
    </Box>
  );
};

export default PatientsDot;
