import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendOTP } from "../../app/apiManager/otpServices";
import { PRODUCTION } from "../../const";
import { saveSteps, setStep } from "../../reducers/appointmentInfoSlice";

const TIMEOUT = 60;

const OTPTimer = ({
  defaultText = "Send OTP",
  captchaVerified,
  btnText,
  setBtnText,
  isDisabled,
  setCaptchaVerified,
  setIsDisabled,
  phoneNo,
  customFunc = null,
  onClick = () => {},
  onReset = () => {},
}) => {
  const [timer, setTimer] = useState(TIMEOUT);
  const dispatch = useDispatch();
  const activeStep = useSelector((state) => state.appointmentInfo.step);

  useEffect(() => {
    let timeout = null;
    if (timer !== 0 && isDisabled) {
      timeout = setTimeout(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setIsDisabled(false);
    }
    if (timer === 0) {
      if (customFunc) {
        customFunc();
      } else {
        dispatch(
          setStep({
            step: 1,
          })
        );
      }

      setBtnText("Resend OTP");
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [timer, isDisabled]);

  useEffect(() => {
    if (captchaVerified) {
      setTimer(TIMEOUT);
      setIsDisabled(true);
      setCaptchaVerified(false);
      if (PRODUCTION) {
        sendOTP(
          phoneNo,
          () => {
            if (customFunc) {
              customFunc();
            } else {
              dispatch(
                setStep({
                  step: 2,
                })
              );
            }
          },
          () => {}
        );
      } else {
        if (customFunc) {
          customFunc();
        } else {
          dispatch(
            setStep({
              step: 2,
            })
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captchaVerified]);

  return (
    <Button
      variant="contained"
      {...(isDisabled && { disabled: true })}
      onClick={() => {
        onClick();
      }}
    >
      {isDisabled ? `Resend ${timer}` : btnText}
    </Button>
  );
};

export default OTPTimer;
