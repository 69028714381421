import { Box, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import IOSSwitch from "../../components/IOSSwitch/IOSSwitch";
import FilePresentIcon from "@mui/icons-material/FilePresent";

const FeatureCard = () => {
  return (
    <Paper
      elevation={2}
      sx={{ width: { xs: "100%", sm: "15rem", md: "17rem" }, padding: 1.2 }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "1.3rem" }}>Drug Allergies</Typography>
        <IOSSwitch />
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
        <FilePresentIcon sx={{ fontSize: "2rem" }} />
      </Box>
    </Paper>
  );
};

export default FeatureCard;
