import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getAppointmentPrescription } from "../../app/apiManager/apiManager";
import done from "../../assets/done.jpg";
import FlexBox from "../../components/FlexBox/FlexBox";

const StepFour = ({ appointment, appointmentCancelled }) => {
  const [queryParams, setQueryParams] = useSearchParams();

  const [prescriptionLink, setPrescriptionLink] = useState(null);

  useEffect(() => {
    const appointmentId = queryParams.get("appointmentId");

    if (!appointmentCancelled) {
      //get the prscription link and set it in the btn
      getAppointmentPrescription(
        appointmentId,
        (response) => {
          if (response.status === "success") {
            setPrescriptionLink(
              `https://doctoronline.lk/Clinic/Prescription?Id=${response.data?.prescriptionId}`
            );
          }
        },
        (error) => {
          // console.log(error);
        },
        () => {}
      );
    }
  }, []);
  return (
    <FlexBox
      sx={{
        height: { xs: "30rem", sm: "22rem" },
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <FlexBox>
        <img src={done} alt="" width={"300px"} />
      </FlexBox>
      <FlexBox sx={{ flexDirection: "column" }}>
        <Typography
          fontSize={"1.5rem"}
          fontWeight="bold"
          sx={{
            ...(appointmentCancelled
              ? { color: "red" }
              : { color: "darkGreen" }),
          }}
        >
          {appointmentCancelled
            ? "Appointment Cancelled"
            : "Appointment Completed"}
        </Typography>
        <Typography>Thank you for using doctor online service</Typography>
        <Button
          variant="outlined"
          sx={{ mt: 2 }}
          {...((appointmentCancelled || prescriptionLink === null) && {
            disabled: true,
          })}
          {...(prescriptionLink === null && {
            title: "No prescritpion available for this appointment",
          })}
          onClick={() => {
            window.open(prescriptionLink, "_blank");
          }}
        >
          Download Prescription
        </Button>
      </FlexBox>
    </FlexBox>
  );
};

export default StepFour;
