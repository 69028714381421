import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Button, Typography } from "@mui/material";
import {InfoIcon} from "../../theme/overrides/CustomIcons";

const ShowAlert = ({ onClick }) => {
  return (
    <Box
      display={"flex"}
      my={1}
      flexDirection="column"
      justifyContent={"center"}
      alignItems="center"
    >
      <InfoIcon sx={{ fontSize: "6rem" }} color="success" />
      <Typography sx={{ mt: 2, fontSize: { xs: "1rem", md: "1.5rem" } }}>
        Appointment is Free. (2023-04-05)
      </Typography>
      <Typography fontSize="0.8rem" sx={{ mt: 0.5, mb: 2 }}>
        Thank you for choosing doctor online
      </Typography>
      <Button color="success" style={{color:"white"}} variant="contained" sx={{ mt: 1 }} onClick={onClick}>
        Okay
      </Button>
    </Box>
  );
};

export default ShowAlert;
