import styled from "@emotion/styled";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newPatient } from "../../app/apiManager/patientServices";
import Required from "../../components/Required/Required";
import { setStep } from "../../reducers/appointmentInfoSlice";
import { generateDobGenderAge, validateNIC } from "../../utils/validateNIC";
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./AddPatient.css";

const StyledTextField = styled(TextField)({
  ".MuiInput-underline": {
    marginTop: 3,
  },
});

const AddPatient = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      gender: "",
    },
  });

  const [date, setDate] = useState(new Date());
  const [isDobGenderDisabled, setIsDobGenderDisabled] = useState(false);
  const [nic, setNic] = useState("");
  const [nicError, setNicError] = useState({
    error: false,
    message: "",
  });
  const { phoneNo } = useSelector((state) => state.appointmentInfo);
  const [onSaveError, setOnSaveError] = useState({
    error: false,
    message: "",
  });

  const onSubmit = (data) => {
    //check nic again

    let { validNic } = validateNIC(nic);
    if (nic === "") {
      validNic = true;
    }
    if (validNic) {
      setNicError({
        error: false,
        message: "",
      });
      newPatient(
        data.title,
        data.fname,
        data.lname,
        data.address,
        phoneNo,
        nic,
        data.birthday,
        data.gender,
        (data, response) => {
          if (data.Status === 1000) {
            dispatch(setStep({ step: 3 }));
          } else {
            if (data.Status === 1001) {
              setOnSaveError({
                error: true,
                message: "An error occured! NIC already exists",
              });
            }
          }
        }
      );
    } else {
      setNicError({
        error: true,
        message: "Please check your NIC",
      });
    }
  };

  const generateNameError = (titleError, fnameError, lnameError) => {
    let errorMessage = "";

    if (titleError && fnameError && lnameError) {
      errorMessage = "Title, First Name and Last Name are required";
      return errorMessage;
    }
    if (titleError && fnameError) {
      errorMessage = "Title and First Name are required";
      return errorMessage;
    }
    if (titleError && lnameError) {
      errorMessage = "Title and Last Name are required";
      return errorMessage;
    }
    if (fnameError && lnameError) {
      errorMessage = "First Name and Last Name are required";
      return errorMessage;
    }
    if (titleError) {
      errorMessage = "Title is required";
      return errorMessage;
    }
    if (fnameError) {
      errorMessage = "First Name is required";
      return errorMessage;
    }
    if (lnameError) {
      errorMessage = "Last Name is required";
      return errorMessage;
    }
    return errorMessage;
  };

  const dispatch = useDispatch();

  return (
    <Grid item xs={12} sm lg sx={{ minheight: "25rem", mt: { xs: 2, sm: 0 } }}>
      <Typography fontWeight={"bold"} fontSize={"1.3rem"}>
        Add Patient
      </Typography>

      <Grid container mt={2} columnSpacing={2}>
        <Grid item xs={2}>
          <Typography fontWeight={"bold"}>
            Title
            <Required />
          </Typography>
          <FormControl variant="standard" sx={{ mt: 0 }}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Title is required",
                },
              }}
              control={control}
              name="title"
              render={({ field }) => {
                return (
                  <Select
                    sx={{ mt: 0.5 }}
                    {...field}
                    {...(errors.title && {
                      error: true,
                    })}
                  >
                    <MenuItem value="">Title</MenuItem>
                    <MenuItem value="Mr.">Mr.</MenuItem>
                    <MenuItem value="Mrs.">Mrs.</MenuItem>
                    <MenuItem value="Ms.">Ms.</MenuItem>
                    <MenuItem value="Baby">Baby</MenuItem>
                    <MenuItem value="Rev.">Rev.</MenuItem>
                    <MenuItem value="Dr.">Dr.</MenuItem>
                  </Select>
                );
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs>
          <Typography fontWeight={"bold"}>
            First Name <Required />
          </Typography>
          <StyledTextField
            label=" "
            variant="standard"
            sx={{ mt: 0 }}
            {...register("fname", {
              required: {
                value: true,
                message: "First Name is required",
              },
            })}
            {...(errors.fname && {
              error: true,
            })}
          />
        </Grid>
        <Grid item xs>
          <Typography fontWeight={"bold"}>
            Last Name <Required />
          </Typography>
          <StyledTextField
            label=" "
            variant="standard"
            sx={{ mt: 0 }}
            {...register("lname", {
              required: {
                value: true,
                message: "Last Name is required",
              },
            })}
            {...(errors.lname && {
              error: true,
            })}
          />
        </Grid>
      </Grid>
      <Typography
        fontSize={"0.7rem"}
        mt={2}
        color={"#ff0000"}
        sx={{
          "&": {
            marginTop: "8px",
          },
          visibility: "hidden",
          ...((errors.title || errors.fname || errors.lname) && {
            visibility: "unset",
          }),
        }}
      >
        {generateNameError(errors.title, errors.fname, errors.lname)}.
      </Typography>
      <Grid container gap={2} mt={1}>
        <Grid item xs>
          <Typography fontWeight={"bold"}>Address</Typography>
          <StyledTextField
            label=" "
            fullWidth
            variant="standard"
            sx={{
              mt: 0,
            }}
            {...register("address")}
          />
        </Grid>
      </Grid>
      <Typography
        fontSize={"0.7rem"}
        color={"#ff0000"}
        sx={{
          "&": {
            marginTop: "8px",
          },
        }}
      >
        {errors.address?.message}
      </Typography>

      <Grid container columnSpacing={3} mt={2}>
        <Grid item xs={6}>
          <Typography fontWeight={"bold"}>NIC Number</Typography>
          <StyledTextField
            label=" "
            fullWidth
            variant="standard"
            sx={{ mt: 0 }}
            value={nic}
            onChange={(e) => {
              setNic(e.target.value);
              //if the value is 0, no point of validating as the nic is not required
              // if (e.target.value.length === 0) {
              // setNicError({
              //   error: false,
              //   message: "",
              // });
              // setIsDobGenderDisabled(false);
              // return;
              // }
              let {
                year,
                month,
                date,
                gender,
                validNic,
                nicValidationMessage,
              } = validateNIC(e.target.value);

              if (validNic) {
                let dob = new Date(year, month - 1, date);
                setValue("birthday", dob, {});
                setValue("gender", gender, {});
                clearErrors(["gender", "birthday"]);
                setDate(dob);
                setNicError({
                  error: false,
                  message: "",
                });

                //disable the date picker and gender select
                setIsDobGenderDisabled(true);
              } else {
                // setNicError({
                //   error: true,
                //   message: nicValidationMessage,
                // });
                // setIsDobGenderDisabled(false);
              }
            }}
            {...(nicError.error && {
              error: true,
            })}
          />
          <Typography
            fontSize={"0.7rem"}
            mt={2}
            color={"#ff0000"}
            sx={{
              visibility: "hidden",
              ...(nicError.error && {
                visibility: "unset",
              }),
              "&": {
                marginTop: "8px",
              },
            }}
          >
            {nicError.message}.
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography fontWeight={"bold"}>
            Birthday <Required />
          </Typography>
          <input hidden {...register("birthday")} />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={date}
              disabled={isDobGenderDisabled}
              onChange={(newValue) => {
                setDate(newValue);
                setValue("birthday", new Date(newValue), {
                  shouldTouch: true,
                });
              }}
              className="date-picker"
              sx={{ flexGrow: 1, mr: 2, width: { xs: "100%" } }}
              renderInput={(params) => (
                <StyledTextField
                  label=" "
                  variant="standard"
                  sx={{
                    mt: 0,
                  }}
                  {...params}
                  fullWidth
                  {...(errors.birthday && {
                    error: true,
                  })}
                />
              )}
            />
          </LocalizationProvider>
          <Typography
            fontSize={"0.7rem"}
            mt={2}
            color={"#ff0000"}
            sx={{
              "&": {
                marginTop: "8px",
              },
            }}
          >
            {errors.birthday?.message}.
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        mt={2}
        // {...(!lastNameValidation.isVisible && {
        //   mt: 4,
        // })}
      >
        <Grid item xs={12} lg>
          <Typography fontWeight={"bold"}>
            Gender <Required />
          </Typography>
          <Controller
            name="gender"
            control={control}
            rules={{
              required: {
                value: true,
                message: "gender is required",
              },
            }}
            render={({ field }) => (
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "gender is required",
                  },
                }}
                control={control}
                name="gender"
                render={({ field }) => {
                  return (
                    <RadioGroup
                      {...field}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <FormControlLabel
                        value="MALE"
                        control={<Radio disabled={isDobGenderDisabled} />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="FEMALE"
                        control={<Radio disabled={isDobGenderDisabled} />}
                        label="Female"
                      />
                    </RadioGroup>
                  );
                }}
              />
            )}
          />

          <Typography
            fontSize={"0.7rem"}
            mt={2}
            color={"#ff0000"}
            sx={{
              "&": {
                marginTop: "0px",
              },
              visibility: "hidden",
              ...(errors.gender && {
                visibility: "unset",
              }),
            }}
          >
            {errors.gender?.message}.
          </Typography>
        </Grid>
      </Grid>

      <Box>
        <Typography
          fontSize={"0.8rem"}
          mt={2}
          color={"#ff0000"}
          sx={{
            "&": {
              marginTop: "0px",
            },
            visibility: "hidden",
            ...(onSaveError.error && {
              visibility: "unset",
            }),
          }}
        >
          {onSaveError.message}.
        </Typography>
        <Box mt={1} display="flex" gap={2}>
          <Button variant="contained" onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default AddPatient;
