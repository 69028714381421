import { Box, Grid } from "@mui/material";
import React from "react";
import "react-calendar/dist/Calendar.css";
import backgroundImage from "../../assets/background.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Stepper from "../../components/Stepper/Stepper";
import {
  clearAppointmentStatus,
  setStep,
} from "../../reducers/appointmentStatusSlice";
import StepFour from "./StepFour";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";

const StatusPage = () => {
  const activeStep = useSelector((state) => state.appointmentStatus.step);
  const [queryParams] = useSearchParams();
  const appointmentId = queryParams.get("appointmentId");

  const appointment = useSelector(
    (state) => state.appointmentStatus.appointment
  );
  const dispatch = useDispatch();
  const [appointmentCompleted, setAppointmentCompleted] = useState(false);
  const [doctor, setDoctor] = useState({});
  const [appointmentCancelled, setAppointmentCancelled] = useState(false);
  const [currentDoctorStatus, setCurrentDoctorStatus] = useState({
    status: "",
    color: "blue",
  });
  const setActiveStep = (step) => {
    dispatch(setStep(step));
  };

  useEffect(() => {
    if (Number(appointmentId) !== appointment.AppointmentId) {
      dispatch(clearAppointmentStatus());
    }
  }, []);

  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Grid
        item
        xs={11.5}
        sm={11}
        md={11}
        lg={7}
        sx={{
          backgroundColor: "#FFFFFF",
          position: "relative",
          borderRadius: 3,
          p: { xs: 1, lg: 4 },
          pb: 1,
          minHeight: { xs: "45rem", sm: "35rem", lg: "35rem" },
          my: { xs: 3, lg: 0 },
        }}
      >
        <Stepper
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          appointmentCompleted={appointmentCompleted}
        />
        <Box
          sx={{
            px: 2,
            borderRadius: 2,
            mt: 2,
          }}
        >
          {activeStep === 1 && (
            <StepOne
              setActiveStep={setActiveStep}
              appointment={appointment}
              doctor={doctor}
              setDoctor={setDoctor}
              setAppointmentCompleted={setAppointmentCompleted}
              setAppointmentCancelled={setAppointmentCancelled}
            />
          )}
          {activeStep === 2 && (
            <StepTwo
              setActiveStep={setActiveStep}
              appointment={appointment}
              currentDoctorStatus={currentDoctorStatus}
              setCurrentDoctorStatus={setCurrentDoctorStatus}
            />
          )}
          {activeStep === 3 && (
            <StepThree
              setActiveStep={setActiveStep}
              appointment={appointment}
              setAppointmentCompleted={setAppointmentCompleted}
              setAppointmentCancelled={setAppointmentCancelled}
            />
          )}
          {activeStep === 4 && (
            <StepFour
              appointment={appointment}
              appointmentCancelled={appointmentCancelled}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default StatusPage;
