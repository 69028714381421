import { Grid } from "@mui/material";
import React from "react";
import HeaderItem from "./HeaderItem";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import VaccinesIcon from "@mui/icons-material/Vaccines";
const Stepper = ({ activeStep, setActiveStep, appointmentCompleted }) => {
  return (
    <>
      {/* stepper header */}
      <Grid container justifyContent={"center"} ml={1} pr={2}>
        <HeaderItem
          title={"Make Appointment"}
          stepNo="01"
          icon={<DoneAllIcon />}
          // {...(activeStep === 1 && {
          //   state: "On - Going",
          // })}
          {...(activeStep === 1 && {
            state: "COMPLETED",
          })}
          {...(activeStep < 1 && {
            state: "PENDING",
          })}
          {...(appointmentCompleted && {
            state: "COMPLETED",
          })}
          setActiveStep={setActiveStep}
        />
        <HeaderItem
          title={"Waiting for Doctor"}
          stepNo="02"
          icon={<AccessTimeIcon />}
          {...(activeStep === 2 && {
            state: "On - Going",
          })}
          {...(activeStep > 2 && {
            state: "COMPLETED",
          })}
          {...(activeStep < 2 && {
            state: "PENDING",
          })}
          {...(appointmentCompleted && {
            state: "COMPLETED",
          })}
          setActiveStep={setActiveStep}
        />
        <HeaderItem
          title={"Consultation"}
          stepNo="03"
          icon={<VaccinesIcon />}
          {...(activeStep === 3 && {
            state: "On - Going",
          })}
          {...(activeStep > 3 && {
            state: "COMPLETED",
          })}
          {...(activeStep < 3 && {
            state: "PENDING",
          })}
          {...(appointmentCompleted && {
            state: "COMPLETED",
          })}
          setActiveStep={setActiveStep}
        />
        <HeaderItem
          hasNext={false}
          xs={{ xs: 2, sm: 1.2 }}
          title={"Completed"}
          stepNo="04"
          icon={<LibraryAddCheckIcon />}
          // {...(activeStep === 4 && {
          //   state: "On - Going",
          // })}
          {...(activeStep === 4 && {
            state: "COMPLETED",
          })}
          {...(activeStep < 4 && {
            state: "PENDING",
          })}
          {...(appointmentCompleted && {
            state: "COMPLETED",
          })}
          setActiveStep={setActiveStep}
        />
      </Grid>
    </>
  );
};

export default Stepper;
