import { Grid } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import {
  saveSteps,
  setPatientDetails,
} from "../../reducers/appointmentInfoSlice";
import { signIn } from "../../reducers/signInSlice";

const calculateAge = (dob) => {
  const today = new Date();
  const birthday = new Date(dob);
  let age = today.getFullYear() - birthday.getFullYear();
  return age;
};

const TableRow = ({
  patientId,
  gender = "",
  email,
  selectedId,
  name,
  dob,
  onClick,
  patient = {},
}) => {
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => {
        dispatch(
          setPatientDetails({
            patientName: name,
            patientId: patientId,
            patientEmail: email,
          })
        );
        dispatch(signIn({ ...patient }));
        onClick(patientId);
      }}
    >
      <Grid
        container
        py={1}
        // {...(selectedId === patientId && {
        //   sx: { backgroundColor: "lightgoldenrodyellow" },
        // })}
        sx={{
          border: "1px solid transparent",
          backgroundColor:
            selectedId === patientId ? "lightgoldenrodyellow" : "unset",
          "&:hover": {
            border: "1px solid blue",
            cursor: "pointer",
          },
        }}
      >
        <Grid item xs ml={2} display="flex" alignItems={"center"}>
          {name}
        </Grid>
        <Grid item xs={2} ml={2} display="flex" justifyContent={"center"}>
          {gender.substring(0, 1)}
        </Grid>
        <Grid
          item
          xs={2}
          ml={2}
          mr={2}
          display="flex"
          justifyContent="center"
          alignItems={"center"}
        >
          {calculateAge(dob) > 0 ? calculateAge(dob) : 0} yrs
        </Grid>
      </Grid>
    </div>
  );
};

export default TableRow;
