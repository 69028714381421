const months = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const validateNIC = (nic = "") => {
  let validNic = false;
  let nicValidationMessage = "";
  let oldNIC = false;
  //   let nic = nicField.value;
  //   let nic = "990811130V";

  if (nic.length === 0) {
    // showErrors(nicField, nicError, "Please enter NIC number");
    nicValidationMessage = "NIC is required";
    validNic = false;
  } else {
    if (nic.length === 12) {
      if (nic.match(/^\d{12}/)) {
        validNic = true;
        oldNIC = false;
      } else {
        // showErrors(nicField, nicError, "Invalid NIC format");
        nicValidationMessage = "Please enter a valid NIC number";
        validNic = false;
      }
    } else if (nic.length === 10) {
      if (nic.match(/^\d{9}[vxVX]{1}/)) {
        validNic = true;
        oldNIC = true;
      } else {
        // showErrors(nicField, nicError, "Invalid NIC format");
        nicValidationMessage = "Please enter a valid NIC number";
        validNic = false;
      }
    } else {
      nicValidationMessage = "Please enter a valid NIC number";
      validNic = false;
    }

    let gender = "";
    let year;
    let month;
    let date;
    let monthDate;
    let yearDigits;

    if (validNic) {
      //set nic as correct
      nicValidationMessage = "";
      if (oldNIC) {
        yearDigits = Number(nic.substring(0, 2));
        monthDate = Number(nic.substring(2, 5));
        year = 1900 + yearDigits;
      } else {
        yearDigits = Number(nic.substring(0, 4));
        monthDate = Number(nic.substring(4, 7));
        year = yearDigits;
      }

      if (monthDate > 500) {
        monthDate = monthDate - 500;
        gender = "FEMALE";
      } else {
        gender = "MALE";
      }

      let tempMd = monthDate;

      for (let i = 0; i < 12; i++) {
        if (tempMd <= months[i]) {
          month = i + 1;
          break;
        } else {
          tempMd = tempMd - months[i];
        }
      }

      // date
      for (let i = 0; i < month - 1; i++) {
        monthDate = monthDate - months[i];
      }
      date = monthDate;

      return { year, month, date, gender, validNic };
    } else {
      //invalid nic
      //set field validation fail
      //   showErrors(nicField, nicError, "Please enter a valid NIC number");
      return { year, month, date, gender, validNic, nicValidationMessage };
    }
  }
  return {
    validNic,
    nicValidationMessage,
  };
};

export const generateDobGenderAge = (nicNo, setErrorFunc, setValueFunc) => {
  // console.log("nicNo", nicNo);
  const [year, month, date, gender, isNicvalid] = validateNIC(
    nicNo,
    setErrorFunc
  );
  if (isNicvalid) {
    //setting gender
    if (gender === "Male") {
      setValueFunc("gender", "MALE");
    } else {
      setValueFunc("gender", "FEMALE");
    }

    //setting dob
    let numOfMonthDigits = month.toString().length;
    if (numOfMonthDigits === 2) {
      setValueFunc("birthday", `${year}-${month}-${date}`);
    } else {
      setValueFunc("birthday", `${year}-0${month}-${date}`);
    }
  }
  //   } else {
  //     //resetting fields to defaults
  //     ageField.value = "";
  //     maleRadioBtn.checked = false;
  //     femaleRadioBtn.checked = false;
  //     dobField.value = "";
  //   }
};
